import React, { useEffect, useState } from "react";
import style from './index.module.less'
import { FilterSvg } from '@/images/news/index'
import Axios from "axios";
import useBigScreen from "@/hooks/bigScreen"



const JPNewsEvent = ({eventList}) => {
    const [ifShowMore, setIfShowMore] = useState(false)
    return (
        <div className={style.box}>
            <div className={style.title}>イベント一覧</div>
            <div className={style.content}>
                {
                    eventList.length > 6 ? <>
                        {eventList.slice(0, 6).map((item, index) => (
                            <div className={style.listItem}  onClick={() => window.open(item.link)}
                                key={`jpnews_event_${index}`}
                            >
                                <div className={style.listItemImg} style={{backgroundImage:`url(${item.img})`}}>

                                </div>
                                <div className={style.listItemDate}>
                                    {item.date}
                                </div>
                                <div className={style.listItemTitle}>
                                    {item.name}
                                </div>
                             
                            </div>
                        ))}
                    </> : <>
                        {eventList.map((item, index) => (
                            <div className={style.listItem}
                                key={`jpnews_event_${index}`}
                                onClick={() => window.open(item.link)}
                            >
                                <div className={style.listItemImg}>

                                </div>
                                <div className={style.listItemDate}>
                                    {item.date}
                                </div>
                                <div className={style.listItemTitle}>
                                    {item.name}
                                </div>
                               
                            </div>
                        ))}
                    </>
                }
             
            </div>
            {eventList.length > 6&& !ifShowMore && <div className={style.showMore} onClick={()=>setIfShowMore(true)}>更多 +</div>}
            {eventList.length > 6&&ifShowMore&& <div className={style.content}>
               
                        {eventList.slice(6).map((item, index) => (
                            <div className={style.listItem}  onClick={() => window.open(item.link)}
                                key={`jpnews_event_${6 + index}`}
                            >
                                <div className={style.listItemImg}>

                                </div>
                                <div className={style.listItemDate}>
                                    {item.date}
                                </div>
                                <div className={style.listItemTitle}>
                                    {item.name}
                                </div>
                               
                            </div>
                        ))}
                         <div className={style.listItem} 
                                key={`jpnews_event_${eventList.length + 1}`}
                            >
                            </div>
                    
             
            </div>}
         
        </div>
    )
}

export default JPNewsEvent