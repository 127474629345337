import React, { useEffect, useState } from "react";
import style from './index.module.less'
import { FilterSvg } from '@/images/news/index'
import Axios from "axios";
import useBigScreen from "@/hooks/bigScreen"



const JPNewsKol = ({kolList}) => {
   
    return (
        <div className={style.box}>
            <div className={style.title}>AR で切り開く未来</div>
            <div className={style.desc}>多くの人に信頼され、選ばれています</div>
            <div className={style.content}>
                <div className={style.kol}>
                    {kolList.map((item, index) => (
                        <div className={style.kolItem} key={`jp_news_kol${index}`} onClick={()=>window.open(item.link)}>
                            <div className={style.kolItemImg} style={{ backgroundImage: `url(${item.img})` }}>

                            </div>
                            <div className={style.kolItemBox}>
                                <div className={style.kolItemBoxTop}>
                                    <div className={style.kolItemBoxTopMsg}>
                                        <div className={style.kolItemBoxTopMsgIcon} style={{ backgroundImage: `url(${item.icon})` }}>

                                        </div>
                                        <div className={style.kolItemBoxTopMsgName} >
                                            {item.name}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.kolItemBoxDesc}>{item.desc}</div>
                            </div>
                        </div>
                    ))}
                </div>

            </div>
        </div>
    )
}

export default JPNewsKol