import React, { useEffect, useState } from "react";
import style from './index.module.less'
import { FilterSvg } from '@/images/news/index'
import Axios from "axios";
import useBigScreen from "@/hooks/bigScreen"



const JPNewsReview = ({reviewList}) => {
    
    return (
        <div className={style.box}>
            <div className={style.title}>Media Review</div>
            <div className={style.content}>
                <div className={style.kol}>
                    {reviewList.map((item, index) => (
                        <div className={style.kolItem} key={`jp_news_kol${index}`} onClick={()=>window.open(item.link)}>
                            <div className={style.kolItemImg} style={{ backgroundImage: `url(${item.img})` }}>

                            </div>
                            <div className={style.kolItemDate}>
                                {item.date}
                            </div>
                            <div className={style.kolItemDesc}>
                                {item.desc}
                            </div>
                            <div className={style.kolItemLink} onClick={()=>window.open(item.link)}>
                              Read More
                            </div>
                        </div>
                    ))}
                </div>

            </div>
        </div>
    )
}

export default JPNewsReview